import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { updateFaqformData, getfaqdata } from "../../Controller/Faq";
import { useParams } from "react-router-dom";
import Addfaqpopup from "./Editpopup";
function EditFaqData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    question: "",
    answer: "",
  });

  const handleInputChange = (value, field) => {
    if (field === "answer") {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === "question") {
      setFormData((prevVal) => ({
        ...prevVal,
        question: value, // Corrected from 'title' to 'question'
      }));
    } else {
      const name = field;
      const inputValue = value;

      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };
  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 06-02-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.question) {
        setErrors((preError) => ({
          ...preError,
          question: "Question is required.",
        }));
      } else if (!formData.answer) {
        setErrors((preError) => ({
          ...preError,
          answer: "Answer is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateFaqformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            question: "",
            answer: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  /*************************************************************
   * Function Name  : getfaqfetchdata
   * Purposs        : This function is used get data
   * Created Date   : 06-02-2024
   *************************************************************/
  const getfaqfetchdata = async () => {
    const options = { id: edit };
    try {
      const result = await getfaqdata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id;
        setFormData(modifiedData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getfaqfetchdata();
  }, []);
  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Edit Faq Content</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputText">
                            Question<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="question"
                            name="question"
                            value={formData.question}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "question")
                            }
                          />
                          {errors?.question ? (
                            <p style={{ color: "red" }}>{errors?.question}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Answer<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="answer"
                            name="answer"
                            value={formData.answer}
                            onChange={(value) =>
                              handleInputChange(value, "answer")
                            }
                          />
                          {errors?.answer ? (
                            <p style={{ color: "red" }}>{errors?.answer}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="misrambhu_total_btn_for_gap">
                          <button
                            type="submit"
                            className="btn btn-primary all_btn_focus"
                            disabled={isSubmitting}
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            {isSubmitting ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Update"
                            )}
                          </button>
                          <Link to="/faqListPage">
                            <button
                              type="button"
                              className="btn btn-primary all_btn_focus"
                              style={{
                                backgroundColor: "#891621",
                                borderColor: "#5f1e24",
                              }}
                            >
                              <span>Cancel</span>
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Addfaqpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}
export default EditFaqData;
