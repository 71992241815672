import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";

import { useLocation, Link } from "react-router-dom";
import { imageURL } from "../Config/constant";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
function Orderdetail() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const location = useLocation();
  const selectedItem = location.state.selectedItem;
  const selectedItemvariant = location.state.selectedItem.cartItems;
  const [responseData, setResponseData] = useState(null); // Define responseData state

  function formatDate(timestamp) {
    // Convert timestamp to milliseconds by removing the last 2 characters (milliseconds)
    const milliseconds = Number(String(timestamp).slice(0, -2));

    // Create a Date object from the milliseconds
    const date = new Date(milliseconds);

    // Define options for formatting
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    // Return the formatted date string
    return date.toLocaleDateString(undefined, options);
  }
  const formdatacreate = formatDate(selectedItem.createdAt);
  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleViewInvoice = () => {
    const image = "https://imageluar.s3.ap-south-1.amazonaws.com/image/luar+image2.png";
    const invoiceElement = document.createElement('div');
    invoiceElement.style.width = '900px';
    invoiceElement.style.margin = '0 auto';
    invoiceElement.style.padding = '20px';
    invoiceElement.style.background = '#f9f4f1';

    const subTotal = selectedItem.cartItems.reduce((total, item) => {
      const colorSizeDetail = item.product_id.variants.find(
        (colorSize) => colorSize.volume === item.volume
      );
      const mrpPrice = colorSizeDetail ? colorSizeDetail.price : 0; // Default to 0 if colorSizeDetail not found
      return total + (mrpPrice * item.qty);
    }, 0);

    // Calculate GST Amount (assuming 18% GST on each item)
    const gstAmount = selectedItem.cartItems.reduce((total, item) => total + (item.gst || 0) * item.qty, 0);

    // Calculate Grand Total (including Sub Total, GST Amount, and Delivery Charges)
    const deliveryCharges = selectedItem.delivery_charges || 0;
    const grandTotal = subTotal + gstAmount + deliveryCharges;

    const invoiceContent = selectedItem.cartItems.map((item, index) => {
      const colorSizeDetail = item.product_id.variants.find(
        (colorSize) => colorSize.volume === item.volume
      );

      return `
        <tr>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 100px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${index + 1}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 200px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${capitalizeFirstLetter(item.product_name)} (${item.productvariant}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${item.product_id.hsn}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">${item.qty}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">INR ${colorSizeDetail ? colorSizeDetail.price : 'N/A'}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">INR ${item.discount || 0}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">INR ${item.gst || 0}</td>
            <td style="padding: 10px; border: 1px solid #ddd; font-family: 'quicksand-medium'; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b;">INR ${item.price}</td>
        </tr>
    `;
    }).join('');
    invoiceElement.innerHTML = `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Invoice</title>
    <style>
      @font-face {
        font-family: "hermitage-regular";
        src: url("./fonts//Hermitage\ Regular.otf");
      }

      @font-face {
        font-family: "quicksand-bold";
        src: url("./fonts//Quicksand-Bold.ttf");
      }

      @font-face {
        font-family: "quicksand-medium";
        src: url("/fonts/Quicksand-Medium.ttf");
      }

      @font-face {
        font-family: "quicksand-light";
        src: url("/fonts/Quicksand-Light.ttf");
      }

      @font-face {
        font-family: "quicksand-semiBold";
        src: url("/fonts/Quicksand-SemiBold.ttf");
      }

      @font-face {
        font-family: "quicksand-regular";
        src: url("/fonts/Quicksand-Regular.ttf");
      }
    </style>
  </head>
  <body
    style="
      font-family: Arial, sans-serif;
      font-size: 14px;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      background: #fff;
      margin-top: 20px;
      margin-bottom: 20px;
    "
  >
    <div
      style="
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 20px;
        background: #fff;
        border: 1px solid #dddddd;
      "
    >
      <table style="width: 100%; margin-bottom: 20px; margin-top: 40px">
        <tr>
          <td
            style="
              width: 50%;
              vertical-align: top;
              font-family: 'Times New Roman', serif;
            "
          >
            <div>
              <img
                src="./image.png"
                alt="mishrambu logo"
                style="max-width: 165px"
              />
              <h2
                style="
                  margin: 0;
                  font-size: 25px;
                  margin-bottom: 20px;
                  font-family: hermitage-regular;
                  color: #000;
                "
              >
                TAX INVOICE
              </h2>
            </div>
            <span
              style="font-family: quicksand-bold; color: #000; font-size: 18px"
            >
              SOLD BY: LOREM PRIVATE LIMITED
            </span>
            <br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
              ;
              >806, DLH Park, Near MTNL Telephone Exch,<br />
              S.V Road, Goregaon West- 400104<br />
              Mumbai, Maharashtra, India.<br />
              <br />
              GSTIN: 27AAHCE2432N1ZG</span
            >
          </td>
          <td
            style="
              width: 50%;
              vertical-align: top;
              font-family: 'Times New Roman', serif;
            "
          >
            <span
              style="font-family: quicksand-bold; color: #000; font-size: 18px"
              >BILL TO: ${capitalizeFirstLetter(selectedItem.user_id.fullName)} </span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
            >
              Delivery Address: ${selectedItem.address_id.land_mark} ${selectedItem.address_id.city} ${selectedItem.address_id.state} ${selectedItem.address_id.country} , ${selectedItem.address_id.postal_code}</span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
              >Billing Address: ${selectedItem.address_id.land_mark} ${selectedItem.address_id.city} ${selectedItem.address_id.state} ${selectedItem.address_id.country} , ${selectedItem.address_id.postal_code} </span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
              >Phone:${selectedItem.user_id.mobile}</span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
              >Email:${selectedItem.user_id.email}</span
            ><br />
            <br />
            <br />
            <br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
              >Payment Method:${capitalizeFirstLetter(selectedItem.payment_mode)}</span
            ><br />
            <span
              style="
                font-size: 18px;
                color: #000;
                font-family: quicksand-medium;
              "
              >Delivery Method:Online</span
            >
          </td>
        </tr>
      </table>
      <table style="width: 100%; margin-bottom: 20px">
        <tr>
          <td
            style="
              width: 50%;
              font-size: 18px;
              color: #000;
              font-family: quicksand-medium;
            "
          >
            Invoice Date:${new Date(selectedItem.created_date).toLocaleDateString()}
          </td>
          <td
            style="
              width: 50%;
              font-size: 18px;
              color: #000;
              font-family: quicksand-medium;
            "
          >
            Order Number:${selectedItem.order_id}
          </td>
        </tr>
        <tr>
          <td
            style="font-size: 18px; color: #000; font-family: quicksand-medium"
            ;
          >
            Invoice Number:${selectedItem.order_id}
          </td>
          <td
            style="font-size: 18px; color: #000; font-family: quicksand-medium"
          >
            Order Date:${new Date(selectedItem.created_date).toLocaleDateString()}
          </td>
        </tr>
      </table>
      <!-- <div style="text-align: center; margin-top: 28px; margin-bottom: 0px">
        <span
          style="
            font-size: 25px;
            font-family: 'hermitage-regular';
            color: #785f6e;
            text-align: center;
          "
          >ORDER SUMMARY</span
        >
      </div> -->

      <div
        style="border: 1px solid #ddd; border-radius: 20px; overflow: hidden"
      >
        <table style="width: 100%; border-collapse: collapse">
          <thead>
            <tr>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  /* border-top-left-radius: 10px; */
                  border-right: 1px solid #c9c3c3;
                "
              >
                #
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  border-right: 1px solid #c9c3c3;
                "
              >
                Item Description
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  border-right: 1px solid #c9c3c3;
                "
              >
                HSN Code
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  border-right: 1px solid #c9c3c3;
                "
              >
                Qty
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  border-right: 1px solid #c9c3c3;
                "
              >
                Unit Price
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  border-right: 1px solid #c9c3c3;
                "
              >
                Discount
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  border-right: 1px solid #c9c3c3;
                "
              >
                GST
              </th>
              <th
                style="
                  padding: 15px;
                  background: #ddd;
                  font-family: 'quicksand-bold';
                  font-size: 17px;
                  color: #000;
                  /* border-top-right-radius: 10px; */
                "
              >
                Total Amount
              </th>
            </tr>
          </thead>
          <tbody>
                <!-- Dynamically populate order details -->
               ${invoiceContent}
                 <tr>
              <td style="padding: 5px; padding-top: 50px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="7">Sub Total:</td>
              <td style="padding: 5px; padding-top: 50px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${subTotal.toFixed(2)}</td>
            </tr>
            <!-- GST Amount (18%) row -->
            <tr>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="7">GST Amount (18%):</td>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${gstAmount.toFixed(2)}</td>
            </tr>
            <!-- Delivery Charges row -->
            <tr>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="7">Delivery Charges:</td>
              <td style="padding: 5px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';">INR ${deliveryCharges.toFixed(2)}</td>
            </tr>
            <!-- Grand Total row -->
            <tr>
              <td style="padding: 5px; padding-bottom: 50px; max-width: 150px; word-wrap: break-word; text-align: end; font-size: 16px; color: #765c6b; font-family: 'quicksand-medium';" colspan="7">Grand Total:</td>
              <td style="padding: 5px; padding-bottom: 50px; max-width: 150px; word-wrap: break-word; text-align: center; font-size: 16px; font-family: 'quicksand-medium'; color: #765c6b;">INR ${grandTotal.toFixed(2)}</td>
            </tr>
              </tbody>
           
        </table>
      </div>
      <div
        style="
          text-align: center;
          margin-top: 10px;
          font-size: 12px;
          margin-bottom: 35px;
        "
      >
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #000;
          "
        >
          Kind Regards, Mishrambu Customer Service | Need help? Contact us at
          +91 9999999999 or hello@lorem.co.in</span
        ><br />
        <br />
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #000;
          "
        >
          Corporate Office: 501, X Cube building, off New Link Rd, Veera Desai
          Industrial Estate,</span
        ><br />
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #000;
          "
          >Andheri West, Mumbai- 400102.</span
        ><br />
        <br />
        <br />
        <br />
        <span
          style="
            font-size: 16px;
            font-family: 'quicksand-medium';
            color: #000;
            font-weight: 900;
          "
        >
          www.lorem.in</span
        >
      </div>
    </div>
  </body>
</html>


  `;

    document.body.appendChild(invoiceElement);

    html2canvas(invoiceElement).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new tab
      window.open(pdfUrl, '_blank');

      // Clean up: Remove the temporary invoiceElement
      document.body.removeChild(invoiceElement);
    }).catch((error) => {
      console.error('Error generating PDF:', error);
    });

  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    {/* <h5>Product Detail</h5> */}
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div
                      className="my_add_event_form_field"
                      style={{ float: "right" }}
                    >
                      <Link to="/orderList">
                        <button type="button" className="btn btn-primary all_btn_focus" style={{ backgroundColor: "#891621", borderColor: "#5f1e24" }}>
                          Back
                        </button>
                      </Link>
                      {/* <button type="button" className="btn btn-primary ml-2" onClick={handleViewInvoice}>
                        View Invoice
                      </button> */}
                    </div>
                    <div className="responsive">
                      <div>
                        <div className="form-group customer_details_form_div" >
                          <label htmlFor="exampleInputText">
                            <h3>Customer Detail :- </h3>
                          </label>
                          <p>Name :- <span>{capitalizeFirstLetter(selectedItem.user_id.fullName)}</span></p>
                          <p>Email :- <span>{selectedItem.user_id.email}</span></p>
                          <p>Mobile No: <span>{selectedItem.user_id.mobile}</span></p>
                          <p>Shipping Address: <span>{selectedItem.address_id.land_mark} {selectedItem.address_id.city} {selectedItem.address_id.state} {selectedItem.address_id.country} , {selectedItem.address_id.postal_code}</span></p>
                        </div>
                        <div className="form-group">
                          <h3>
                            Order Details:
                          </h3>
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Product Image</th>
                                <th scope="col">Product Name</th>
                                <th scope="col">Product Volume</th>
                                <th scope="col">Price</th>
                                <th scope="col">Quantity</th>
                                {/* <th scope="col">Total Price</th>
                                <th scope="col">Tax</th>
                                <th scope="col">Coupon Code</th>
                                <th scope="col">Shipping Charge</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {selectedItem.cartItems && selectedItem.cartItems.map((orderDetail, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    {/* Loop through each variant */}
                                    {orderDetail.variants.map((variant, variantIndex) => (
                                      <React.Fragment key={`${index}_${variantIndex}`}>
                                        {/* Display the image and volume in a new row */}
                                        {orderDetail.product_id.variants.map((productVariant, productVariantIndex) => {
                                          if (productVariant._id === variant.volume && productVariant.images.length > 0) {
                                            return (
                                              <tr key={`${index}_${variantIndex}_${productVariantIndex}`}>

                                                <td>
                                                  <img
                                                    src={`${imageURL}${productVariant.images[0]}`}
                                                    style={{ width: "100px", height: "100px" }}
                                                    alt={`Image ${variantIndex + 1}`}
                                                  />
                                                </td>
                                                <td>{capitalizeFirstLetter(orderDetail.product_id.product_name)}</td>
                                                <td>
                                                  {productVariant.volume}
                                                </td>
                                                <td>
                                                  {variant.price}
                                                </td>
                                                <td>
                                                  {variant.quantity}
                                                </td>
                                              </tr>
                                            );
                                          }
                                          return null;
                                        })}
                                      </React.Fragment>
                                    ))}
                                    {/* <tr>
          <td>{selectedItem.total_price}</td>
          <td>{selectedItem.taxes}</td>
          <td>{selectedItem.coupon_code}</td>
          <td>{selectedItem.shipping_charges}</td>
        </tr> */}
                                  </React.Fragment>
                                );
                              })}


                            </tbody>






                          </table>
                          <div className="customer_details_form_div">
                            <p>Total Price :- <span> {selectedItem.total_price}</span></p>
                            <p>Tax :- <span>{selectedItem.taxes}</span></p>
                            <p>Coupon Code :- <span>{selectedItem.coupon_code}</span></p>
                            <p>Shipping Charge :- <span> {selectedItem.shipping_charges}</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Orderdetail;
