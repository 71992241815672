import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
// import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import OrderPopUp from "./OrderPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import Pagination from "@mui/material/Pagination";
import { getBulkOrderDatafetch } from "../Controller/Order";
import { BsThreeDots } from "react-icons/bs";
import CategoryBulkDelete from "./Bulkdelete";
import { MdOutlineRemoveRedEye } from "react-icons/md";


function BulkOrder() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [itemIdTocancelstatus, setItemIdcancelstatus] = useState(null);
  const [orderList, setOrderData] = useState([]);
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [popUpOpenDelete, setPopUpOpenDelete] = useState(false);
  const togglePopUp = (itemId, deliveryStatus) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(deliveryStatus);
    getOrderdata();
  };

  const togglePopUpstatus = (itemId, deliveryStatus) => {
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    setItemIdcancelstatus(deliveryStatus);
    getOrderdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getOrderdata = async (page, customer_email) => {
    const options = {
      page: page,
      customer_email: customer_email,
    };

    try {
      const result = await getBulkOrderDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.bulkOrder;
        const totalcount = result.data.response.result.totalcount;
        setOrderData(responseData);
        setTotalItems(totalcount);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getOrderdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getOrderdata(currentPage, value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getOrderdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const capitalizeFirstChar = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };
  const handleViewDetail = (item) => {
    console.log(item);
    navigate('/bulkorderDetail', { state: { selectedItem: item } });
  };
  const togglePopUpDelete = (itemId) => {
    setPopUpOpenDelete(!popUpOpenDelete);
    setItemIdToDelete(itemId);
    getOrderdata();
  };
  const dropdownMenu = (item) => {
    let menuOptions;
      menuOptions = (
        <>
         {/* <Menu.Item key="3" onClick={() => togglePopUpDelete(item._id)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item> */}
          <Menu.Item key="4" onClick={() => handleViewDetail(item)}>
            <MdOutlineRemoveRedEye  />
            <span className="show_span_edit">View Details</span>
          </Menu.Item>
        </>
      );
    //}
  
    return <Menu>{menuOptions}</Menu>;
  };

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Bulk Orders List </h1>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      style={{ color: "#891621", fontWeight: "bold" }}
                    >
                      Bulk Orders Table List
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Seller Name</th>
                            <th scope="col">Seller Shop </th>
                            <th scope="col">Email</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Received on Date</th>
                            <th scope="col">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.length > 0 ? (
                            orderList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                 
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>
                                  {capitalizeFirstChar(item.sellerName)}
                                </td>
                                <td>{capitalizeFirstChar(item.sellerShop)}</td>
                                <td>{item.email}</td>
                                <td>
        {item.products.reduce((totalQuantity, product) => totalQuantity + product.total, 0)}
      </td>
                                <td>
        {item.products.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0)}
      </td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td>
                                    <Dropdown
                                      overlay={() =>
                                        dropdownMenu(item)
                                      }
                                      placement="bottomLeft"
                                      arrow
                                    >
                                      <BsThreeDots />
                                    </Dropdown>
                                    </td>
                               
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
        itemIdTocancelstatus={itemIdTocancelstatus}
      />
       <CategoryBulkDelete
        popUpOpenDelete={popUpOpenDelete}
        togglePopUpDelete={togglePopUpDelete}
        itemIdToDelete={itemIdToDelete}
      ></CategoryBulkDelete>
    </div>
  );
}

export default BulkOrder;
