import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, Input } from "antd";
import { addemailformData } from "../Controller/Email";
import ReactQuill from "react-quill";
import AddEmailpopup from "./AddEmailpopup";
import { Link, useNavigate } from "react-router-dom";
import "./email.css";

function AddEmailData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    mail_type: "",
    from_email: "",
    subject: "",
    mail_header: "",
    mail_body: "",
    mail_footer: "",
    html: "",
  });

  const handleInputChange = (value, field) => {
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: value,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [field]: "",
    }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (!formData.mail_type) {
  //       setErrors((preError) => ({
  //         ...preError,
  //         mail_type: "Email Type is required.",
  //       }));
  //     } else if (!formData.from_email) {
  //       setErrors((preError) => ({
  //         ...preError,
  //         from_email: "Email From is required.",
  //       }));
  //     } else if (!formData.subject) {
  //       setErrors((preError) => ({
  //         ...preError,
  //         subject: "Subject is required.",
  //       }));
  //     } else if (!formData.mail_body) {
  //       setErrors((preError) => ({
  //         ...preError,
  //         mail_body: "Mail Body is required.",
  //       }));
  //     } else if (!formData.mail_footer) {
  //       setErrors((preError) => ({
  //         ...preError,
  //         mail_footer: "Mail Footer is required.",
  //       }));
  //     } else {
  //       setIsSubmitting(true);
  //       const updateResult = await addemailformData(
  //         formData,
  //         adminInformation.token
  //       );
  //       if (updateResult.status) {
  //         setFormData((prevFormData) => ({
  //           ...prevFormData,
  //           mail_type: "",
  //           from_email: "",
  //           subject: "",
  //           mail_header: "",
  //           mail_body: "",
  //           mail_footer: "",
  //           html: "",
  //         }));
  //         setIsSubmitting(false);
  //         togglePopUp();
  //       } else {
  //         // localStorage.removeItem("TOKEN");
  //         // localStorage.removeItem("ADMIN-INFO");
  //         // navigate("/login");
  //         setErrors((preError) => ({
  //           ...preError,
  //           mail_type:
  //             "Mail type already exists. Please choose a different mail type.",
  //         }));
  //         setIsSubmitting(false);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Trim spaces and remove spaces or numbers in specific fields
      const sanitizedFormData = {
        ...formData,
        from_email: formData.from_email?.replace(/\s+/g, "").replace(/[0-9]/g, ""), // Remove all spaces and numbers
        subject: formData.subject?.replace(/[0-9]/g, "").trim(), // Remove numbers and trim leading/trailing spaces
      };
  
      // Perform validations
      if (!sanitizedFormData.mail_type) {
        setErrors((preError) => ({
          ...preError,
          mail_type: "Email Type is required.",
        }));
      } else if (!sanitizedFormData.from_email) {
        setErrors((preError) => ({
          ...preError,
          from_email: "Email From is required and cannot contain spaces or numbers.",
        }));
      } else if (!sanitizedFormData.subject) {
        setErrors((preError) => ({
          ...preError,
          subject: "Subject is required and cannot contain numbers.",
        }));
      } else if (!sanitizedFormData.mail_body) {
        setErrors((preError) => ({
          ...preError,
          mail_body: "Mail Body is required.",
        }));
      } else if (!sanitizedFormData.mail_footer) {
        setErrors((preError) => ({
          ...preError,
          mail_footer: "Mail Footer is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addemailformData(
          sanitizedFormData,
          adminInformation.token
        );
        if (updateResult.status) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            mail_type: "",
            from_email: "",
            subject: "",
            mail_header: "",
            mail_body: "",
            mail_footer: "",
            html: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        } else {
          setErrors((preError) => ({
            ...preError,
            mail_type:
              "Mail type already exists. Please choose a different mail type.",
          }));
          setIsSubmitting(false);
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Email Template </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="mail_type">
                            Email Type<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control common_category_input_for_all_section "
                            id="mail_type"
                            name="mail_type"
                            value={formData.mail_type}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "mail_type")
                            }
                          >
                            <option value="">Select Email Type</option>
                            <option value="Signup Confirmation">
                              Signup Confirmation
                            </option>
                            <option value="Order Confirmation">
                              Order Confirmation
                            </option>
                          </select>
                          {errors.mail_type && (
                            <p style={{ color: "red" }}>{errors.mail_type}</p>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="from_email">
                            Email From<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="from_email"
                            name="from_email"
                            placeholder="Enter Email From"
                            value={formData.from_email}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "from_email")
                            }
                          />
                          {errors.from_email && (
                            <p style={{ color: "red" }}>{errors.from_email}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor="subject">
                            Email Subject<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="subject"
                            name="subject"
                            placeholder="Enter Subject"
                            value={formData.subject}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "subject")
                            }
                          />
                          {errors.subject && (
                            <p style={{ color: "red" }}>{errors.subject}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        {/* <div className="col-md-12">
                          <label htmlFor="mail_header">Mail Header<span style={{ color: 'red' }}>*</span></label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="mail_header"
                            name="mail_header"
                            value={formData.mail_header}
                            onChange={(value) => handleInputChange(value, 'mail_header')}
                          />
                          {errors.mail_header && <p style={{ color: "red" }}>{errors.mail_header}</p>}
                        </div> */}
                      </div>
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor="mail_body">
                            Mail Body<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="mail_body"
                            name="mail_body"
                            value={formData.mail_body}
                            onChange={(value) =>
                              handleInputChange(value, "mail_body")
                            }
                          />
                          {errors.mail_body && (
                            <p style={{ color: "red" }}>{errors.mail_body}</p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor="mail_footer">
                            Mail Footer<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="mail_footer"
                            name="mail_footer"
                            value={formData.mail_footer}
                            onChange={(value) =>
                              handleInputChange(value, "mail_footer")
                            }
                          />
                          {errors.mail_footer && (
                            <p style={{ color: "red" }}>{errors.mail_footer}</p>
                          )}
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <div className="col-md-12">
                          <label htmlFor="html">Html</label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="html"
                            name="html"
                            value={formData.html}
                            onChange={(value) => handleInputChange(value, 'html')}
                          />
                          {errors.html && <p style={{ color: "red" }}>{errors.html}</p>}
                        </div>
                      </div> */}
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary all_btn_focus"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <Link to="/emailListPage">
                          <button
                            type="button"
                            className="btn btn-primary all_btn_focus"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddEmailpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEmailData;
