import React, { useState,useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { updateMainformData,getMainData} from '../Controller/Main';
import Addmainpopup from "./Addmainpopup";
// import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Main.css";
import { useParams} from "react-router-dom";

function EditMainData() {
  const adminInformationString = localStorage.getItem('ADMIN-INFO');
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
    const [errors, setErrors] = useState('');
    const [popUpOpen, setPopUpOpen] = useState(false);
    const togglePopUp = () => {
      setPopUpOpen(!popUpOpen); 
    };
   // console.log(adminInformation);
    const [formData, setFormData] = useState({
      title: "",
      description: "",
      image_1: null,
      image_2: null,
      image_3: null,
      
    });
     /*************************************************************
 * Function Name  : handleInputChange
 * Created Date   : 10-01-2024
 *************************************************************/
     const [fileInputs, setFileInputs] = useState({
      image_1: null,
      image_2: null,
      image_3: null,
    });
    
    const handleFileChange1 = (e) => {
      const file = e.target.files[0];
      setFileInputs((prevFileInputs) => ({
        ...prevFileInputs,
        image_1: file,
      }));
    };
  
    const handleFileChange2 = (e) => {
      const file = e.target.files[0];
      setFileInputs((prevFileInputs) => ({
        ...prevFileInputs,
      image_2: file,
      }));
    };
    const handleFileChange3 = (e) => {
      const file = e.target.files[0];
      setFileInputs((prevFileInputs) => ({
        ...prevFileInputs,
      image_3: file,
      }));
    };
  
     const handleInputChange = (e) => {
      const { name, value} = e.target;
        setFormData((prevVal) => ({
          ...prevVal,
          [name]: value,
        }));
        setErrors((prevError) => ({
          ...prevError,
          [name]: '',
        }));
    };
    
    
    
    
 
  /*************************************************************
 * Function Name  : handleSubmit
 * Purposs        : This function is used add social icon data
 * Created Date   : 10-01-2024
 *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
     if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: 'Title is required.',
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: 'Detail is required.',
        }));
      }else{
        const updateResult = await updateMainformData(formData, adminInformation.token);
        if(updateResult.status === true){
            setFormData((prevFormData) => ({
                ...prevFormData,
              title: "",
              description: "",
              image_1: null,
              image_2: null,
              image_3: null,
            }));
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
 /*************************************************************
 * Function Name  : getmaindata
 * Purposs        : This function is used get data
 * Created Date   : 09-01-2024
 *************************************************************/
 const getmaindata = async () => {
  const options = { id: edit };
  try {
    const result = await getMainData(options, adminInformation.token);
    if (result.data && result.data.response) {
      const responseData = result.data.response.result;
      // Replace _id with id in the response data
      const modifiedData = { ...responseData, id: responseData._id };
      delete modifiedData._id; // Remove the _id property

      setFormData(modifiedData);
      setFileInputs({
        image_1: responseData.image_1 || null,
        image_2: responseData.image_2 || null,
        image_3: responseData.image_3 || null,
      });
    } else {
      console.error("Unexpected response format:", result.data);
    }
  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};

  useEffect(() => {
    window.scrollTo(0, 0);
    getmaindata();
  }, []);
   /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
 

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit  Main Page </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group">
                        <label htmlFor="fullname">Title</label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="title"
                          name="title"
                          onChange={handleInputChange} value={formData.title}
                          placeholder="Enter Social Icon Link"
                        />
                         {errors?.title?(<p style={{color:"red"}}>{errors?.title}</p>):''}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleTextarea">Details</label>
                        <textarea
                          className="form-control common_category_input_for_all_section"
                          id="description"
                          name="description" value={formData.description}
                          onChange={handleInputChange}
                          rows="3"
                        ></textarea>
                           {errors?.description?(<p style={{color:"red"}}>{errors?.description}</p>):''}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image 1</label>
                      <input
                        type="file"
                        className="form-control common_category_input_for_all_section"
                        id="image_1"
                        name="image_1" value=""
                        onChange={handleFileChange1}
                      />
                      {fileInputs.image_1 && (
                        <p>Current File: {fileInputs.image_1}</p>
                      )}
                    </div>
                    <div className="form-group">
                    <label htmlFor="fullname">Image 2</label>
                                          <input
                      type="file"
                      className="form-control common_category_input_for_all_section"
                      id="image_2" value=""
                      name="image_2"
                      onChange={handleFileChange2}
                    />
                    {fileInputs.image_2 && (
                      <p>Current File: {fileInputs.image_2}</p>
                    )}
                    </div>
                    <div className="form-group">
                    <label htmlFor="fullname">Image 3</label>
                                          <input
                      type="file"
                      className="form-control common_category_input_for_all_section"
                      id="image_3" value=""
                      name="image_3"
                      onChange={handleFileChange3}
                    />
                    {fileInputs.image_3 && (
                      <p>Current File: {fileInputs.image_3}</p>
                    )}
                    </div>
                     <button type="submit" className="btn btn-primary all_btn_focus" style={{ backgroundColor: '#891621',borderColor:'#5f1e24' }}>
                        Update
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <Addmainpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMainData;
