import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, Input } from "antd";
import { addemailformData } from "../Controller/Store";
import ReactQuill from "react-quill";
import AddEmailpopup from "./AddStorepopup";
import { Link, useNavigate } from "react-router-dom";
import "./store.css";

function AddStoreData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [formData, setFormData] = useState({
    store_location: "",
    variants: [{ phone_no: "", name: "" }],
  });

  const handleVariantChange = (value, index, field) => {
    setFormData((prevFormData) => {
      const updatedVariants = [...prevFormData.variants];
      updatedVariants[index][field] = value;
      return { ...prevFormData, variants: updatedVariants };
    });
  };

  const addVariant = () => {
    setFormData((prevVal) => ({
      ...prevVal,
      variants: [...prevVal.variants, { phone_no: "", name: "" }],
    }));
  };

  const removeVariant = (index) => {
    setFormData((prevVal) => {
      const updatedVariants = [...prevVal.variants];
      updatedVariants.splice(index, 1);
      return { ...prevVal, variants: updatedVariants };
    });
  };

  const handleInputChange = (value, field) => {
    let modifiedValue = value;
  
    // if (field === "store_location" || field === "description") {
    //   // Remove spaces from the input
    //   modifiedValue = modifiedValue.replace(/\s/g, '');
    // }
    setFormData((prevVal) => ({
      ...prevVal,
      [field]: modifiedValue,
    }));
    setErrors((prevError) => ({
      ...prevError,
      [field]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const nameRegex = /^[a-zA-Z\s]+$/;
      if (!formData.store_location) {
        setErrors((prevError) => ({
          ...prevError,
          store_location: "Store Location is required.",
        }));
      }
      // else if (!nameRegex.test(formData.store_location)) {
      //   setErrors((preError) => ({
      //     ...preError,
      //     store_location: "Store Location cannot contain special characters except spaces.",
      //   }));
      // }
       else if (
        !formData.variants.every((variant) => variant.name && variant.phone_no)
      ) {
        setErrors((prevError) => ({
          ...prevError,
          variants: "All variants must have a name and phone no.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await addemailformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status) {
          setFormData({
            store_location: "",
            variants: [{ phone_no: "", name: "" }],
          });
          setIsSubmitting(false);
          togglePopUp();
        } else {
          setErrors((prevError) => ({
            ...prevError,
            category_name:
              "Category name already exists. Please choose a different name.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Store Data </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="from_email">
                            Store Location
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="store_location"
                            name="store_location"
                            placeholder="Enter Store Location"
                            value={formData.store_location}
                            onChange={(e) =>
                              handleInputChange(
                                e.target.value,
                                "store_location"
                              )
                            }
                          />
                          {errors.store_location && (
                            <p style={{ color: "red" }}>
                              {errors.store_location}
                            </p>
                          )}
                        </div>
                      </div>
                      {formData.variants.map((variant, index) => (
                        <div className="form-group row" key={index}>
                          <div className="col-md-6">
                            <label htmlFor={`variant_name_${index}`}>
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control common_category_input_for_all_section "
                              id={`variant_name_${index}`}
                              name={`variant_name_${index}`}
                              placeholder="Enter Name"
                              value={variant.name}
                              onChange={(e) =>
                                handleVariantChange(
                                  e.target.value,
                                  index,
                                  "name"
                                )
                              }
                            />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor={`variant_phone_${index}`}>
                              Phone No
                            </label>
                            <input
                              type="number"
                              className="form-control common_category_input_for_all_section "
                              id={`variant_phone_${index}`}
                              name={`variant_phone_${index}`}
                              placeholder="Enter Phone No"
                              value={variant.phone_no}
                              onChange={(e) =>
                                handleVariantChange(
                                  e.target.value,
                                  index,
                                  "phone_no"
                                )
                              }
                            />
                            {index > 0 && (
                              <button
                                type="button"
                                className="btn btn-danger remove-variant mt-4"
                                onClick={() => removeVariant(index)}
                                style={{
                                  backgroundColor: "#891621",
                                  borderColor: "#5f1e24",
                                }}
                              >
                                Remove
                              </button>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="col-md-2 product_add_button_varient">
                        <button
                          type="button"
                          className="btn btn-primary all_btn_focus"
                          onClick={addVariant}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          Add Data
                        </button>
                      </div>
                      <br></br>
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary all_btn_focus"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <Link to="/storeListPage">
                          <button
                            type="button"
                            className="btn btn-primary all_btn_focus"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddEmailpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStoreData;
