import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import OrderPopUp from "./OrderPopUp";
import OrdercancelPopUp from "./OrdercancelPopUp";
import Pagination from "@mui/material/Pagination";
import { getintouchDatafetch } from "../Controller/Contact";

function ListGetInTouch() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdPass, setItemId] = useState(null);
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstatus, setPopUpOpenstatus] = useState(false);
  const [itemIdPasscancel, setItemIdcancel] = useState(null);
  const [itemIdTocancelstatus, setItemIdcancelstatus] = useState(null);
  const [contactList, setgetData] = useState([]);
  const togglePopUp = (itemId, deliveryStatus) => {
    setPopUpOpen(!popUpOpen);
    setItemId(itemId);
    setItemIdStatus(deliveryStatus);
    getcontactdata();
  };

  const togglePopUpstatus = (itemId, deliveryStatus) => {
    setPopUpOpenstatus(!popUpOpenstatus);
    setItemIdcancel(itemId);
    setItemIdcancelstatus(deliveryStatus);
    getcontactdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getcontactdata = async (page, email) => {
    const options = {
      page: page,
      email: email,
    };

    try {
      const result = await getintouchDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.getInTouch;
        const totalcount = result.data.response.result.totalcount;
        setgetData(responseData);
        setTotalItems(totalcount);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getcontactdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getcontactdata(currentPage, value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getcontactdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const capitalizeFirstChar = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };

  const dropdownMenu = (itemId, deliveryStatus) => {
    let menuOptions;

    if (deliveryStatus === "initialize") {
      menuOptions = (
        <>
          <Menu.Item key="2" onClick={() => togglePopUp(itemId, "processing")}>
            <FaEdit />
            <span className="show_span_edit">Processing</span>
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => togglePopUpstatus(itemId, "order cancel")}
          >
            <FaEdit />
            <span className="show_span_edit">Cancel Order</span>
          </Menu.Item>
        </>
      );
    } else if (deliveryStatus === "processing") {
      menuOptions = (
        <>
          <Menu.Item
            key="2"
            onClick={() => togglePopUp(itemId, "out of delivery")}
          >
            <FaEdit />
            <span className="show_span_edit">Out of Delivery</span>
          </Menu.Item>
          <Menu.Item
            key="3"
            onClick={() => togglePopUpstatus(itemId, "order cancel")}
          >
            <FaEdit />
            <span className="show_span_edit">Cancel Order</span>
          </Menu.Item>
        </>
      );
    } else if (deliveryStatus === "out of delivery") {
      menuOptions = (
        <>
          <Menu.Item key="4" onClick={() => togglePopUp(itemId, "delivered")}>
            <MdDelete />
            <span className="show_span_edit">Delivered</span>
          </Menu.Item>
          <Menu.Item
            key="5"
            onClick={() => togglePopUpstatus(itemId, "order cancel")}
          >
            <FaEdit />
            <span className="show_span_edit">Cancel Order</span>
          </Menu.Item>
        </>
      );
    } else if (deliveryStatus === "delivered") {
      menuOptions = (
        <Menu.Item key="5">
          <span className="show_span_edit">Delivered</span>
        </Menu.Item>
      );
    }

    return <Menu>{menuOptions}</Menu>;
  };

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">
                  {" "}
                  Get In Touch Contact List{" "}
                </h1>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    {/* <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <div className="all_common_search_input">
                          <Search
                            placeholder="input search email"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchValue}
                            className="input_type_search_common "
                          />
                        </div>
                      </div>
                    </Form.Group> */}
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      style={{ color: "#891621", fontWeight: "bold" }}
                    >
                      Get In Touch Contact List Table
                    </h6>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Message</th>
                            <th scope="col">Created Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactList.length > 0 ? (
                            contactList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  
                                      {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{capitalizeFirstChar(item.name)}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{capitalizeFirstChar(item.message)}</td>
                                <td>{formatDate(item.createdAt)}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdPass={itemIdPass}
        itemIdToStatus={itemIdToStatus}
      />
      <OrdercancelPopUp
        popUpOpenstatus={popUpOpenstatus}
        togglePopUpstatus={togglePopUpstatus}
        itemIdPasscancel={itemIdPasscancel}
        itemIdTocancelstatus={itemIdTocancelstatus}
      />
    </div>
  );
}

export default ListGetInTouch;
