import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import "../AllCssFile.css";
import { FaDesktop } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import {
  updateTestimonialformData,
  getTestimonialdata,
} from "../Controller/Testimonial";
import { useParams } from "react-router-dom";
import Addtestimonialpopup from "./EditPopUp";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../Config/constant";
function EditTestimonalData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    image: null,
  });

  const handleFileChange1 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };
  const handleInputChange = (value, field) => {
    if (field === "description") {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === "name") {
      setFormData((prevVal) => ({
        ...prevVal,
        name: value,
      }));
    } else {
      const name = field;
      const inputValue = value;

      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  const [fileInputs, setFileInputs] = useState({
    image: null,
  });
  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.name) {
        setErrors((preError) => ({
          ...preError,
          name: "Name is required.",
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: "Detail is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateTestimonialformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: "",
            description: "",
            image: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  /*************************************************************
   * Function Name  : gettestimonialfetchdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const gettestimonialfetchdata = async () => {
    const options = { id: edit };
    try {
      const result = await getTestimonialdata(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        // Replace _id with id in the response data
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id; // Remove the _id property
        setFormData(modifiedData);
        setFileInputs({
          image: responseData.image || null,
        });
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    gettestimonialfetchdata();
  }, []);
  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Add Testimonail Content</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputText">
                            Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={(e) =>
                              handleInputChange(e.target.value, "name")
                            }
                          />
                          {errors?.name ? (
                            <p style={{ color: "red" }}>{errors?.name}</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Description<span style={{ color: "red" }}>*</span>
                          </label>
                          <ReactQuill
                            className="about_us_page_edit_react_quill"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={(value) =>
                              handleInputChange(value, "description")
                            }
                          />
                          {errors?.description ? (
                            <p style={{ color: "red" }}>
                              {errors?.description}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="form-group">
                          <label htmlFor="fullname">Image</label>
                          <ImgCrop rotate aspect={636 / 850}>
                            <Upload
                              className="avatar-uploader"
                              name="image"
                              onChange={({ fileList }) =>
                                handleFileChange1(fileList)
                              }
                              listType="picture-card"
                              fileList={null}
                              beforeUpload={() => false}
                              style={{ width: "100%", height: "100%" }}
                            >
                              {formData.image ? (
                                <img
                                  src={
                                    typeof formData.image === "string"
                                      ? `${imageURL}${formData.image}`
                                      : URL.createObjectURL(formData.image)
                                  }
                                  alt="subcategory"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <div>
                                  <svg
                                    className="icon"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="image"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                    ></path>
                                  </svg>
                                  <div className="ant-upload-text">Upload</div>
                                </div>
                              )}
                            </Upload>
                          </ImgCrop>
                        </div>
                        <div className="misrambhu_total_btn_for_gap">
                          <button
                            type="submit"
                            className="btn btn-primary all_btn_focus"
                            disabled={isSubmitting}
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            {isSubmitting ? (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            ) : (
                              "Submit"
                            )}
                          </button>
                          <Link to="/testimonialListPage">
                            <button
                              type="button"
                               className="btn btn-primary all_btn_focus"
                              style={{
                                backgroundColor: "#891621",
                                borderColor: "#5f1e24",
                              }}
                            >
                              <span>Cancel</span>
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Addtestimonialpopup
            popUpOpen={popUpOpen}
            togglePopUp={togglePopUp}
          />
        </div>
      </div>
    </div>
  );
}
export default EditTestimonalData;
