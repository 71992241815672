import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import ProductDeletePopUp from "./ProductDeletePopUp";
import ProductOutPopUp from "./ProductStockPopUp";
import Pagination from "@mui/material/Pagination";
import { getProductDatafetch } from "../Controller/Product";
import CategoryChange from "./statusChange";
import {
  LikeOutlined,
} from "@ant-design/icons";








function ListProduct() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [itemIdToOut, setItemIdToOut] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstock, setPopUpOpenstock] = useState(false);
  const [productList, setProductData] = useState([]);
  const [categories, setCategoryData] = useState([]);

  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getProductdata();
  };
  const handleRowClickout = (itemId, out_stock_status) => {
    setPopUpOpenstock(!popUpOpenstock);
    setItemIdToOut(itemId);
    setItemIdStatus(out_stock_status);
    getProductdata();
  };
  const capitalizeFirstChar = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getProductdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getProductDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.product;
        const totalcount = result.data.response.result.totalcount;

        setProductData(responseData);
        setTotalItems(totalcount);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleRowClick = (itemId) => {
    navigate(`/editProductData/${itemId}`);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getProductdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getProductdata(currentPage, value);
  };
  const capitalizeAfterSpace = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(status === "A" ? "I" : "A");
    getProductdata();
  };
  const dropdownMenu = (itemId, out_stock_status, status) => {
    let menuOptions;

    if (out_stock_status === "A") {
      menuOptions = (
        <>
          <Menu.Item key="1" onClick={() => handleRowClick(itemId)}>
            <FaEdit />
            <span className="show_span_edit">Edit</span>
          </Menu.Item>
          {/* <Menu.Item key="2" onClick={() => handleRowClickout(itemId, "I")}>
            <FaEdit />
            <span className="show_span_edit">Out of Stock</span>
          </Menu.Item> */}
          <Menu.Item key="2" onClick={() => togglePopUpchange(itemId, status)}>
            <LikeOutlined />
            <span className="show_span_edit">
              {status === "A" ? "Inactive" : "Active"}
            </span>
          </Menu.Item>
          <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
            <MdDelete />
            <span className="show_span_edit">Delete</span>
          </Menu.Item>
        </>
      );
    } else {
      menuOptions = (
        <>
          <Menu.Item key="1" onClick={() => handleRowClick(itemId)}>
            <FaEdit />
            <span className="show_span_edit">Edit</span>
          </Menu.Item>
          {/* <Menu.Item key="2" onClick={() => handleRowClickout(itemId, "A")}>
            <FaEdit />
            <span className="show_span_edit">In Stock</span>
          </Menu.Item> */}
          <Menu.Item key="2" onClick={() => togglePopUpchange(itemId, status)}>
            <LikeOutlined />
            <span className="show_span_edit">
              {status === "A" ? "Inactive" : "Active"}
            </span>
          </Menu.Item>
          <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
            <MdDelete />
            <span className="show_span_edit">Delete</span>
          </Menu.Item>
        </>
      );
    }

    return <Menu>{menuOptions}</Menu>;
  };

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Products List </h1>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                   
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <div>
                      <h6
                        className="m-0 font-weight-bold "
                        style={{ color: "#891621", fontWeight: "bold" }}
                      >
                        Products List Table
                      </h6>
                    </div>
                    <div className="product_create_product_linkk">
                      <Link to="/createProductData">
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          Add Product
                        </button>
                      </Link>
                      <Link to="/productBulkData">
                        <button
                          type="button"
                          className="btn btn-primary all_btn_focus"
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          Add Bulk Product
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Category</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Updated Date</th>
                            {/* <th scope="col">Stock Status</th> */}
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.length > 0 ? (
                            productList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>
                                  {capitalizeAfterSpace(item.category_name)}
                                </td>
                                <td>
                                  {capitalizeAfterSpace(item.product_name)}
                                </td>
                                <td>{formatDate(item.createdAt)}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                {/* <td
                                  style={{
                                    color:
                                      item.out_stock_status === "A"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {item.out_stock_status === "A"
                                    ? "In Stock"
                                    : "Out of Stock"}
                                </td> */}
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() =>
                                      dropdownMenu(
                                        item._id,
                                        item.out_stock_status,
                                        item.status
                                      )
                                    }
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductDeletePopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      />
      <ProductOutPopUp
        popUpOpenstock={popUpOpenstock}
        handleRowClickout={handleRowClickout}
        itemIdToOut={itemIdToOut}
        itemIdToStatus={itemIdToStatus}
      />
      <CategoryChange
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange} // Assuming status is available in your data
      />
    </div>
  );
}

export default ListProduct;
