import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload } from "antd";
import "../AllCssFile.css";
import "react-quill/dist/quill.snow.css";
import { addProductBulkData } from "../Controller/Product";
import AddProductpopup from "./AddProductpopup";
import { useParams, useNavigate, Link } from "react-router-dom";

function ProductBulkCreate() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const [formData, setFormData] = useState({
    file: "",
  });

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({ ...prevFormData, file: file }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.file) {
        setErrors((preError) => ({
          ...preError,
          file: "File is required.",
        }));
      } else {
        const updateResult = await addProductBulkData(
          formData,
          adminInformation.token
        );
        console.log(updateResult.status);
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            file: "",
          }));
          togglePopUp();
        } else {
          localStorage.removeItem("TOKEN");
          localStorage.removeItem("ADMIN-INFO");
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { Dragger } = Upload;

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <div className="about_us_page_showing_page_heading">
                  <div>
                    <h5>Upload Bulk Product</h5>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="responsive">
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <div className="form-group row">
                          <div className="col-md-6">
                            <label htmlFor="fullname">File</label>
                            <input
                              type="file"
                              className="form-control common_category_input_for_all_section"
                              id="file"
                              name="file"
                              onChange={handleFileChange2}
                            />
                            <p style={{ color: "red" }}>
                              File format: Excel/CSV (size: 100MB)
                            </p>
                            {errors?.file ? (
                              <p style={{ color: "red" }}>{errors?.file}</p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="misrambhu_total_btn_for_gap">
                          <button
                            type="submit"
                            className="btn btn-primary all_btn_focus"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            Submit
                          </button>
                          <Link to="/productListPage">
                            <button
                              type="button"
                              className="btn btn-primary all_btn_focus"
                              style={{ marginLeft: "12px" }}
                            >
                              <span>Cancel</span>
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AddProductpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
        </div>
      </div>
    </div>
  );
}

export default ProductBulkCreate;
