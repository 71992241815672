import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import {
  updateGetInTouchformData,
  getintouchData,
} from "../../Controller/GetInTouch";
import AddGetInTouchModel from "./editpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./GetInTouchData.css";
import { Link, useParams } from "react-router-dom";

function GetInTouchEdit() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    email: "",
    address: "",
    phone_no: "",
  });

  /*************************************************************
   * Function Name  : getprofiledata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getInTouchFormdata = async () => {
    const options = { id: edit };
    try {
      const result = await getintouchData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        const modifiedData = { ...responseData, id: responseData._id };
        delete modifiedData._id;
        setFormData(modifiedData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getInTouchFormdata();
  }, []);

  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((preVal) => ({
      ...preVal,
      [name]: value,
    }));
    setErrors((preError) => ({
      ...preError,
      [name]: "",
    }));
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.email) {
        setErrors((preError) => ({
          ...preError,
          email: "Email is required.",
        }));
      } else if (!formData.address) {
        setErrors((preError) => ({
          ...preError,
          address: "Address is required.",
        }));
      } else if (!formData.phone_no) {
        setErrors((preError) => ({
          ...preError,
          phone_no: "Phone no is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await updateGetInTouchformData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === true) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            email: "",
            address: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Get In Touch </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Email<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="email"
                          name="email"
                          value={formData.email}
                          placeholder="Enter email"
                          onChange={handleInputChange}
                        />
                        {errors?.email ? (
                          <p style={{ color: "red" }}>{errors?.email}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Address<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="address"
                          value={formData.address}
                          name="address"
                          onChange={handleInputChange}
                          placeholder="Enter Address"
                        />
                        {errors?.address ? (
                          <p style={{ color: "red" }}>{errors?.address}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">
                          Phone No<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control common_category_input_for_all_section"
                          id="phone_no"
                          value={formData.phone_no}
                          name="phone_no"
                          onChange={handleInputChange}
                          placeholder="Enter Phone no"
                        />
                        {errors?.phone_no ? (
                          <p style={{ color: "red" }}>{errors?.phone_no}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary all_btn_focus"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Update"
                          )}
                        </button>
                        <Link to="/getInTouchList">
                          <button
                            type="button"
                            className="btn btn-primary all_btn_focus"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddGetInTouchModel
              popUpOpen={popUpOpen}
              togglePopUp={togglePopUp}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetInTouchEdit;
