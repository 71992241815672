import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { login, verifyLoginOtp } from "../Controller/Account";
import "../AllCssFile.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
function Login() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState("");

  const [countdown, setCountdown] = useState(60);
  const [isCounting, setIsCounting] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Password visibility state
  const [showOtp, setShowOtp] = useState(false); // OTP visibility state

  // Function to store input value and clear errors when typing
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Update the formData state with the new value
    setFormData((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  
    // Clear the specific error for the field being typed into
    setErrors((prevError) => ({
      ...prevError,
      formError: "", // Clear the error for the specific field
    }));
  
    // If the password field is being updated, clear the formError (wrong password error)
    if (name === "password" && errors.formError) {
      setErrors((prevError) => ({
        ...prevError,
        formError: "",
      }));
    }else if (name === "otp" && errors.formError) {
      setErrors((prevError) => ({
        ...prevError,
        formError: "",
      }));
    }
  };
   
  

  const handleLogin = async () => {
    if (!formData?.email) {
      setErrors((prevError) => ({
        ...prevError,
        email: "Email is required.",
      }));
    } else if (!formData.password) {
      setErrors((prevError) => ({
        ...prevError,
        password: "Password is required.",
      }));
    } else {
      const options = { email: formData?.email, password: formData.password };
      const result = await login(options);
      if (result.status) {
        setIsOTPSent(true);
        setSuccess((prevSuccess) => ({
          ...prevSuccess,
          formSuccess: result.message,
        }));
        setCountdown(60);
        setIsCounting(true);
      } else {
        setIsOTPSent(false);
        setErrors((prevError) => ({
          ...prevError,
          formError: result.message,
        }));
      }
    }
  };

  const handleVerifyOTP = async () => {
    try {
      if (!formData?.email) {
        setErrors((prevError) => ({
          ...prevError,
          email: "Email is required.",
        }));
      } else if (!formData.otp) {
        setErrors((prevError) => ({
          ...prevError,
          otp: "One Time Password is required.",
        }));
      } else {
        const options = { email: formData?.email, otp: formData.otp };
        const result = await verifyLoginOtp(options);
        if (result.status === true) {
          navigate("/dashboard", { replace: true });
        } else {
          setErrors((prevError) => ({
            ...prevError,
            otp: "Please enter correct OTP.",
          }));
        }
      }
    } catch (error) {
      setErrors((prevError) => ({
        ...prevError,
        formError: "error",
      }));
    }
  };

  const handleEmailChange = async () => {
    setFormData((prevVal) => ({
      ...prevVal,
      password: "",
      otp: "",
    }));
    setIsOTPSent(false);
    setCountdown(0);
  };

  useEffect(() => {
    let countdownInterval;
    if (isCounting) {
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown((prevCountdown) => prevCountdown - 1);
        } else {
          clearInterval(countdownInterval);
          setIsCounting(false);
        }
      }, 1000);
    } else {
      clearInterval(countdownInterval);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [countdown, isCounting]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleShowOtp = () => {
    setShowOtp(!showOtp);
  };

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block">
                    <img
                      src="img/premium-thandai.png"
                      alt=""
                      className="bg-login-image"
                    ></img>
                  </div>
                  <div className="col-lg-6">
                    {!isOTPSent && (
                      <>
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">Welcome</h1>
                          </div>
                          <form className="user">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control form-control-user common_category_input_for_all_section"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter Email Address..."
                                onChange={handleChange}
                                value={formData?.email}
                              />
                              {errors?.email ? (
                                <p style={{ color: "red" }}>{errors?.email}</p>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="form-group position-relative">
                              <input
                                type={showPassword ? "text" : "password"}
                                name="password"
                                className="form-control form-control-user common_category_input_for_all_section"
                                id="exampleInputPassword"
                                placeholder="Password"
                                onChange={handleChange}
                              />
                              <span
                                className="position-absolute"
                                style={{ right: "10px", top: "10px", cursor: "pointer" }}
                                onClick={toggleShowPassword}
                              >
                                <FontAwesomeIcon
                                  icon={showPassword ? faEye : faEyeSlash}
                                />
                              </span>
                              {errors?.password ? (
                                <p style={{ color: "red" }} >
                                  {errors?.password}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            {errors?.formError ? (
                              <p style={{ color: "red" }}>{errors?.formError}</p>
                            ) : (
                              ""
                            )}
                            <NavLink
                              to={false}
                              onClick={handleLogin}
                              className="btn btn-primary btn-user btn-block"
                            >
                              Login
                            </NavLink>
                          </form>
                          <hr />
                          <div className="text-center"></div>
                        </div>
                      </>
                    )}
                    {isOTPSent && (
                      <>
                        <div className="p-5">
                          <div className="text-center">
                            <h1 className="h4 text-gray-900 mb-4">
                              Welcome Back!
                            </h1>
                            {success?.formSuccess ? (
                              <p className="welcome_back_change" style={{ color: "black" }}>
                                {success?.formSuccess}{" "}
                                <NavLink to={false} onClick={handleEmailChange}>
                                  Change
                                </NavLink>{" "}
                              </p>
                            ) : (
                              ""
                            )}
                            {errors?.formError ? (
                              <p style={{ color: "red" }}>{errors?.formError}</p>
                            ) : (
                              ""
                            )}
                          </div>
                          <form className="user">
                            <div className="form-group position-relative">
                              <input
                                type={showOtp ? "text" : "password"}
                                name="otp"
                                className="form-control form-control-user common_category_input_for_all_section"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                placeholder="Enter One Time Password."
                                onChange={handleChange}
                              />
                              <span
                                className="position-absolute"
                                style={{ right: "10px", top: "10px", cursor: "pointer" }}
                                onClick={toggleShowOtp}
                              >
                                <FontAwesomeIcon
                                  icon={showOtp ? faEye : faEyeSlash}
                                />
                              </span>
                              {errors?.otp ? (
                                <p style={{ color: "red" }}>{errors?.otp}</p>
                              ) : (
                                ""
                              )}
                            </div>
                            <NavLink
                              to={false}
                              onClick={handleVerifyOTP}
                              className="btn btn-primary btn-user btn-block"
                            >
                              Submit
                            </NavLink>
                          </form>
                          <hr />
                          <div className="text-center">
                            {isCounting ? (
                              <p style={{ color: "red" }}>
                                {" Time Left :"} {countdown}{" "}
                              </p>
                            ) : (
                              <NavLink
                                className="small"
                                to={false}
                                onClick={handleLogin}
                              >
                                Resend OTP
                              </NavLink>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
