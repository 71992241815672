import React, { useState } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import { FaDesktop } from "react-icons/fa";
import "../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import DeleteBlogPopUp from "./BlogDeletePopUp";
import Pagination from "@mui/material/Pagination";
import { BsThreeDots } from "react-icons/bs";

function CreateCategories() {
  //Sales order delete PopUp
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="2">
        <Link to="/blogEditPage">
          <FaEdit />
          <span className="show_span_edit">Edit</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="3" onClick={togglePopUp}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Create Categories </h1>
                <p className="mb-4">
                  DataTables is a third party plugin that is used to generate
                  the demo table below. For more information about DataTables,
                  please visit the{" "}
                  <a target="_blank" href="https://datatables.net">
                    official DataTables documentation
                  </a>
                </p>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>From</Form.Label>
                        <DatePicker />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>To</Form.Label>
                        <DatePicker />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Filter By</Form.Label>
                        <Select
                          showSearch
                          placeholder="Select a person"
                          optionFilterProp="children"
                          options={[
                            {
                              value: "jack",
                              label: "Jack",
                            },
                            {
                              value: "lucy",
                              label: "Lucy",
                            },
                            {
                              value: "tom",
                              label: "Tom",
                            },
                          ]}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search placeholder="input search text" />
                      </div>
                    </Form.Group>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Create Categories Blog
                    </h6>
                    <button type="button" className="btn btn-primary ">
                      Sales Order Data
                    </button>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Title</th>
                            <th scope="col">Description</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1 </td>
                            <td>Afroj</td>
                            <td>Lorem ipsum, dolor sit amet consectetur ?</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>2 </td>
                            <td>Zain</td>
                            <td>Lorem ipsum, dolor sit amet consectetur ?</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                          <tr>
                            <td>3 </td>
                            <td>Afroj</td>
                            <td>Lorem ipsum, dolor sit amet consectetur ?</td>
                            <Dropdown
                              overlay={dropdownMenu}
                              placement="bottomLeft"
                              arrow
                            >
                              <td>
                                {/* <FaRegCommentDots /> */}
                                <BsThreeDots />
                              </td>
                            </Dropdown>
                          </tr>
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination count={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteBlogPopUp popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
    </div>
  );
}

export default CreateCategories;
