import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Upload, DatePicker } from "antd";
import {
  editOurMissionData,
  getOurMissionData,
} from "../../Controller/OurMission";
import AddOurMissionModel from "./EditPopup";
import ReactQuill from "react-quill";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./OurMissionData.css";
import { useParams, Link } from "react-router-dom";
import ImgCrop from "antd-img-crop";
import { imageURL } from "../../Config/constant";

function OurMissionEdit() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const { edit } = useParams();
  const [errors, setErrors] = useState("");
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  // console.log(adminInformation);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    image: null,
  });

  /*************************************************************
   * Function Name  : getprofiledata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getOurMissiondata = async () => {
    const options = { id: edit };
    try {
      const result = await getOurMissionData(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setFormData({
          ...responseData,
          id: responseData._id, // Set the id property
        });
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const handleFileChange1 = (fileList) => {
    if (fileList && fileList.length > 0) {
      const file = fileList[0].originFileObj;
      setFormData((prevFormData) => ({ ...prevFormData, image: file }));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getOurMissiondata();
  }, []);

  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleInputChange = (value, field) => {
    if (field === "description") {
      setFormData((prevVal) => ({
        ...prevVal,
        [field]: value,
      }));
    } else if (field === "title") {
      setFormData((prevVal) => ({
        ...prevVal,
        title: value,
      }));
    } else {
      const name = field;
      const inputValue = value;

      setFormData((prevVal) => ({
        ...prevVal,
        [name]: inputValue,
      }));
      setErrors((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
  };

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.title) {
        setErrors((preError) => ({
          ...preError,
          title: "Title is required.",
        }));
      } else if (!formData.description) {
        setErrors((preError) => ({
          ...preError,
          description: "Detail is required.",
        }));
      } else {
        setIsSubmitting(true);
        const updateResult = await editOurMissionData(
          formData,
          adminInformation.token
        );
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            title: "",
            description: "",
          }));
          setIsSubmitting(false);
          togglePopUp();
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  /*************************************************************
          End Function
   *************************************************************/
  const { Dragger } = Upload;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Edit Social Icon </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="exampleInputText">
                          Title<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control common_category_input_for_all_section"
                          id="title"
                          value={formData.title}
                          name="title"
                          onChange={(e) =>
                            handleInputChange(e.target.value, "title")
                          }
                        />
                        {errors?.title ? (
                          <p style={{ color: "red" }}>{errors?.title}</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Description<span style={{ color: "red" }}>*</span>
                        </label>
                        <ReactQuill
                          className="about_us_page_edit_react_quill"
                          id="description"
                          value={formData.description}
                          name="description"
                          onChange={(value) =>
                            handleInputChange(value, "description")
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="fullname">Image</label>
                        <ImgCrop rotate aspect={636 / 850}>
                          <Upload
                            className="avatar-uploader"
                            name="image"
                            onChange={({ fileList }) =>
                              handleFileChange1(fileList)
                            }
                            listType="picture-card"
                            fileList={null}
                            beforeUpload={() => false}
                            style={{ width: "100%", height: "100%" }}
                          >
                            {formData.image ? (
                              <img
                                src={
                                  typeof formData.image === "string"
                                    ? `${imageURL}${formData.image}`
                                    : URL.createObjectURL(formData.image)
                                }
                                alt="subcategory"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                            ) : (
                              <div>
                                <svg
                                  className="icon"
                                  aria-hidden="true"
                                  focusable="false"
                                  data-prefix="fas"
                                  data-icon="image"
                                  role="img"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M0 64v384h512V64H0zm480 352H32V96h448v320z"
                                  ></path>
                                </svg>
                                <div className="ant-upload-text">Upload</div>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </div>
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary all_btn_focus"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Update"
                          )}
                        </button>
                        <Link to="/ourMissionListPage">
                          <button
                            type="button"
                            className="btn btn-primary all_btn_focus"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddOurMissionModel
              popUpOpen={popUpOpen}
              togglePopUp={togglePopUp}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurMissionEdit;
