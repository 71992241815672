import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getSocialDatafetch api
 *********************************************************/
export const getProductDatafetch = async (options , authToken)=>{
    try{
        const {page,product_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'product/list-product',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

export const addProductBulkData = async (options, authToken) => {
  try {
    const { file} = options;
    const formData = new FormData();
    if (options.file) {
      formData.append('file', options.file);
    }
    const postData = {
      url: API_ADMIN_URL + 'product/bulk-product',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    const res = await postRequest(postData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    console.error("Error:", error);
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
/*********************************************************
 *  This function is use to getBannerData api
 *********************************************************/
export const getProductdata = async (options , authToken) =>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
 /*********************************************************
 *  This function is use to addBlogformData api
 *********************************************************/
 export const addProductformData = async (options, authToken, variants) => {
  try {
    const { product_name,gst,sku,hsn, page_show_bottom, category_slug,category_name, category, ingredients, recipes, description, page_show, product_image } = options;
    const formData = new FormData();
    formData.append('product_name', product_name);
    formData.append('description', description);
    formData.append('gst', gst);
    formData.append('category', category);
    formData.append('category_name', category_name);
    formData.append('category_slug', category_slug);
    formData.append('ingredients', ingredients);
    formData.append('recipes', recipes);
    formData.append('page_show', page_show);
    formData.append('page_show_bottom', page_show_bottom);
    formData.append('sku', sku);
    formData.append('hsn', hsn);
    variants.forEach((variants, index) => {
      formData.append(`volume_${index}`, variants.volume);
      formData.append(`price_${index}`, variants.price);
      formData.append(`sellingprice_${index}`, variants.sellingprice);
      formData.append(`productstock_${index}`, variants.productstock);
      formData.append(`show_${index}`, variants.show);
      const imagesArray = variants.images;
     
      imagesArray.forEach((image, imageIndex) => {
        formData.append(`images_${index}`, image);
      });
  
    });
    

    const postData = {
      url: API_ADMIN_URL + 'product/add-product',
      postData: formData,
      headers: {
        authToken: authToken,
        'Content-Type': 'multipart/form-data',
      },
    };

    const res = await postRequest(postData);

    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};


   // End of Function
   /*********************************************************
 *  This function is use to addsocialicondata api
 *********************************************************/
   export const updateProductformData = async (options, authToken,variants,imageList) => {
    try {
      const { id,product_image,gst,sku,hsn, product_name, category_name,category_slug,page_show_bottom, category, ingredients, recipes, description, price, page_show } = options;
    
      const formData = new FormData();
      formData.append('id', id);
      formData.append('product_name', product_name);
      formData.append('gst', gst);
      formData.append('description', description);
      formData.append('category', category);
      formData.append('category_name', category_name);
      formData.append('category_slug', category_slug);
      formData.append('ingredients', ingredients);
      formData.append('recipes', recipes);
      formData.append('page_show', page_show);
      formData.append('page_show_bottom', page_show_bottom);
      formData.append('sku', sku);
      formData.append('hsn', hsn);
      
      // variants.forEach((variant, index) => {
      //   formData.append("volume[]", variant.volume);
      //   formData.append("price[]", variant.price);
      //   formData.append("productstock[]", variant.productstock);
      //   formData.append("show[]", variant.show);
      // });  
      
      variants.forEach((variants, index) => {
        formData.append(`volume_${index}`, variants.volume);
        formData.append(`price_${index}`, variants.price);
        formData.append(`sellingprice_${index}`, variants.sellingprice);
        formData.append(`productstock_${index}`, variants.productstock);
        formData.append(`show_${index}`, variants.show);
        const imagesArray = variants.images;
        
        imagesArray.forEach((image, imageIndex) => {
          formData.append(`images_${index}`, image);
        });
    
      });
     
      
      const postData = {
        url: API_ADMIN_URL + 'product/edit-product',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
      console.log(postData);
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

  
/*********************************************************
 *  This function is use to deleteBannerData api
 *********************************************************/


export const statusCategoryData = async (options, authToken) => {
  try {
    const { status, id} = options;
    const postData = {
      url: API_ADMIN_URL + 'product/status',
      postData: options,
      headers: {
        authToken: authToken,
      },
    };
    const res = await postRequest(postData);

    if (res && res.status === 200) {
      return res.data; // Assuming your response has a 'data' property
    } else {
      return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
    }
  } catch (error) {
    console.error('Error updating banner:', error);
    return { status: false, message: 'Under Maintenance, Please try after some time.' };
  }
};
export const deleteProductDatafetch = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'product/delete-product',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};

export const getproducttotalDatafetch = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/get-total',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
/*********************************************************
 *  This function is use to getCategoryDatafetch api
 *********************************************************/
export const getCategoryDatafetch = async (options , authToken)=>{
    try{
        const {}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'category/all-category',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const geStatusDatafetch = async (options , authToken)=>{
  try{
      const {id,out_stock_status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'product/outofstock-product',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
