import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import "../../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import FaqDeletePopUp from "./FaqDeletePopUp";
import Pagination from "@mui/material/Pagination";
import { getFaqDatafetch } from "../../Controller/Faq";
import { BsThreeDots } from "react-icons/bs";
import CategoryChange from "./statusChange";
import {
  LikeOutlined,
} from "@ant-design/icons";



function ListFaq() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [faqList, setFaqData] = useState([]);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getFaqdata();
  };
  /*************************************************************
   * Function Name  : getFaqdata
   * Purposs        : This function is used get data
   * Created Date   : 06-02-2024
   *************************************************************/
  const getFaqdata = async (page, question) => {
    const options = {
      page: page,
      question: question,
    };

    try {
      const result = await getFaqDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.faq;
        const totalcount = result.data.response.result.totalcount;
        setFaqData(responseData);
        setTotalItems(totalcount);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleRowClick = (itemId) => {
    navigate(`/editFaqData/${itemId}`);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getFaqdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getFaqdata(currentPage, value);
  };
  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(status === "A" ? "I" : "A");
    getFaqdata();
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getFaqdata(currentPage, searchValue);
  }, [currentPage, searchValue]);

  const dropdownMenu = (itemId,status) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => togglePopUpchange(itemId, status)}>
        <LikeOutlined  />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );
  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">   FAQs List </h1>
                <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      style={{ color: "#891621", fontWeight: "bold" }}
                    >
                        FAQs List Table
                    </h6>
                    <Link to="/createFaqData">
                      <button
                        type="button"
                        className="btn btn-primary all_btn_focus"
                        style={{
                          backgroundColor: "#891621",
                          borderColor: "#5f1e24",
                        }}
                      >
                        Add Faq
                      </button>
                    </Link>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S. No</th>
                            <th scope="col">Question</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {faqList.length > 0 ? (
                            faqList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                 
                                      {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{item.question}</td>
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() => dropdownMenu(item._id,item.status)}
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    {/* <FaRegCommentDots /> */}
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FaqDeletePopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      />
       <CategoryChange
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange} // Assuming status is available in your data
      />
    </div>
  );
}

export default ListFaq;
