import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Upload, DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Form } from "react-bootstrap";
import { addCoupenformData, getCoupenDatafetch } from "../Controller/Coupen";
import AddCoupenpopup from "./AddCoupenpopup";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./Coupen.css";
import { Link, useNavigate } from "react-router-dom";

function AddCoupenData() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [errors, setErrors] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  // console.log(adminInformation);
  const [popUpOpen, setPopUpOpen] = useState(false);
 
  
 
  const [formData, setFormData] = useState({
    coupon_code: "",
    discount: "",
    start_date:"",
    start_time:"",
    end_date:"",
    end_time:"",
    quantity:"",
    eligible_amount:"",
    max_amount:"",
  });
  /*************************************************************
   * Function Name  : handleInputChange
   * Created Date   : 10-01-2024
   *************************************************************/
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let modifiedValue = value;

    if (name === "coupon_code") {
        // Remove special characters (allow only letters, numbers, and spaces)
        modifiedValue = modifiedValue.replace(/[^a-zA-Z0-9\s]/g, ''); // Disallow special characters
        modifiedValue = modifiedValue.replace(/\s{2,}/g, ' ');        // Replace multiple spaces with one
        modifiedValue = modifiedValue.trim();                        // Trim spaces from both ends
    }

    setFormData((prevState) => ({
        ...prevState,
        [name]: name === "coupon_code" ? modifiedValue : value,
        ...(name === "start_date" && { end_date: "" }), // Clear end_date when start_date changes
    }));

    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", // Clear the specific field's error
    }));
};

  

  /*************************************************************
   * Function Name  : handleSubmit
   * Purposs        : This function is used add social icon data
   * Created Date   : 10-01-2024
   *************************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const nameRegex = /^[a-zA-Z0-9]+$/; // Allows only letters and numbers, no spaces or special characters
  
      // Trim spaces for validation
      const sanitizedCouponCode = formData.coupon_code?.trim();
  
      if (!sanitizedCouponCode) {
        setErrors((preError) => ({
          ...preError,
          coupon_code: "Coupon code is required.",
        }));
      } else if (!nameRegex.test(sanitizedCouponCode)) {
        setErrors((preError) => ({
          ...preError,
          coupon_code: "Coupon Code can only contain letters and numbers, no spaces or special characters.",
        }));
      } else if (!formData.discount) {
        setErrors((preError) => ({
          ...preError,
          discount: "Discount is required.",
        }));
      } else if (!formData.start_date) {
        setErrors((preError) => ({
          ...preError,
          start_date: "Start date is required.",
        }));
      } else if (!formData.start_time) {
        setErrors((preError) => ({
          ...preError,
          start_time: "Start time is required.",
        }));
      } else if (!formData.end_date) {
        setErrors((preError) => ({
          ...preError,
          end_date: "End Date is required.",
        }));
      } else if (!formData.end_time) {
        setErrors((preError) => ({
          ...preError,
          end_time: "End time is required.",
        }));
      } else if (!formData.quantity) {
        setErrors((preError) => ({
          ...preError,
          quantity: "Quantity is required.",
        }));
      } else if (!formData.eligible_amount) {
        setErrors((preError) => ({
          ...preError,
          eligible_amount: "Eligible amount is required.",
        }));
      } else if (!formData.max_amount) {
        setErrors((preError) => ({
          ...preError,
          max_amount: "Max amount is required.",
        }));
      } else {
  
        // Update sanitized formData for API submission
        const sanitizedFormData = {
          ...formData,
          coupon_code: sanitizedCouponCode,
        };
  
        const updateResult = await addCoupenformData(
          sanitizedFormData,
          adminInformation.token
        );
  
        if (updateResult.status === 200) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            coupon_code: "",
            discount: "",
          }));
          togglePopUp();
        } else {
          setErrors((preError) => ({
            ...preError,
            coupon_code:
              "Coupon Code already exists. Please choose a different coupon code.",
          }));
        }
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  
  /*************************************************************
          End Function
   *************************************************************/
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Add Coupon </h1>
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                      <div className="form-group row">
                        <div className="col-md-6">
                          <label htmlFor="coupon_code">
                            Coupon Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="coupon_code"
                            name="coupon_code"
                            placeholder="Enter Coupen Code"
                            onChange={handleInputChange}
                          />
                          {errors?.coupon_code ? (
                            <p style={{ color: "red" }}>
                              {errors?.coupon_code}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="discount">
                            Discount<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control common_category_input_for_all_section "
                            id="discount"
                            name="discount"
                            placeholder="Enter Discount"
                            onChange={handleInputChange}
                          />
                          {errors?.discount ? (
                            <p style={{ color: "red" }}>{errors?.discount}</p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6 mt-3">
                          <label htmlFor="start_date">Start Date<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="date"
                            className="form-control"
                            id="start_date"
                            name="start_date"
                            placeholder="Enter Start Date"
                            min={new Date().toISOString().split("T")[0]}
                            onChange={(e) => handleInputChange(e, "start_date")}
                          />
                          {errors?.start_date ? (
                            <p style={{ color: "red" }}>
                              {errors?.start_date}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label htmlFor="start_time">Start Time<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="time"
                            className="form-control"
                            id="start_time"
                            name="start_time"
                            placeholder="Enter Start Time"
                            onChange={(e) => handleInputChange(e, "start_time")}
                          />
                          {errors?.start_time ? (
                            <p style={{ color: "red" }}>
                              {errors?.start_time}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label htmlFor="end_date">End Date<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="date"
                            className="form-control"
                            id="end_date"
                            name="end_date"
                            placeholder="Enter End Date"
                            min={
                              formData.start_date
                                ? new Date(new Date(formData.start_date).getTime() + 24 * 60 * 60 * 1000)
                                  .toISOString()
                                  .split("T")[0]
                                : new Date().toISOString().split("T")[0]
                            } // Set minimum to one day after start_date
                            onChange={(e) => handleInputChange(e, "end_date")}
                            disabled={!formData.start_date}
                          />
                          {errors?.end_date ? (
                            <p style={{ color: "red" }}>
                              {errors?.end_date}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-md-6 mt-3">
                          <label htmlFor="end_time">End Time<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="time"
                            className="form-control"
                            id="end_time"
                            name="end_time"
                            placeholder="Enter End Time"
                            onChange={(e) => handleInputChange(e, "end_time")}
                          />
                          {errors?.end_time ? (
                            <p style={{ color: "red" }}>
                              {errors?.end_time}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label htmlFor="quantity">Quantity<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="quantity"
                            name="quantity"
                            placeholder="Enter Quantity"
                            onChange={(e) => handleInputChange(e, "quantity")}
                          />
                          {errors?.quantity ? (
                            <p style={{ color: "red" }}>
                              {errors?.quantity}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label htmlFor="coupon_code">Eligible Amount<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="eligible_amount"
                            name="eligible_amount"
                            placeholder="Enter Eligible Amount"
                            onChange={(e) => handleInputChange(e, "eligible_amount")}
                          />
                          {errors?.eligible_amount ? (
                            <p style={{ color: "red" }}>
                              {errors?.eligible_amount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mt-3">
                          <label htmlFor="coupon_code">Maximum Amount<span style={{ color: 'red' }}>*</span></label>
                          <input
                            type="number"
                            className="form-control"
                            id="max_amount"
                            name="max_amount"
                            placeholder="Enter Maximum Amount"
                            onChange={(e) => handleInputChange(e, "max_amount")}
                          />
                          {errors?.max_amount ? (
                            <p style={{ color: "red" }}>
                              {errors?.max_amount}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="misrambhu_total_btn_for_gap">
                        <button
                          type="submit"
                          className="btn btn-primary all_btn_focus"
                          disabled={isSubmitting}
                          style={{
                            backgroundColor: "#891621",
                            borderColor: "#5f1e24",
                          }}
                        >
                          {isSubmitting ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                        <Link to="/coupenListPage">
                          <button
                            type="button"
                            className="btn btn-primary all_btn_focus"
                            style={{
                              backgroundColor: "#891621",
                              borderColor: "#5f1e24",
                            }}
                          >
                            <span>Cancel</span>
                          </button>
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <AddCoupenpopup popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCoupenData;
