import React, { useEffect, useState } from "react";
import "./DashBaord.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Header from "../HeaderDashBoard/Header";
import SideBar from "../SideBar/SideBar";
import Footer from "../Footer/Footer";
import { getproducttotalDatafetch } from "../Controller/Product";
import { getordertotalDatafetch } from "../Controller/Order";
import { getcategorytotalDatafetch } from "../Controller/Category";
import { getcoupontotalDatafetch } from "../Controller/Coupen";
import { getEmailTotal } from "../Controller/Email";
import { getBlogDataTotal } from "../Controller/Blog";
import { getStoreTotal } from "../Controller/Store";
import { getUserdeailTotal } from "../Controller/User";
import { Chart } from "chart.js/auto";

function Dashboard() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [totalproduct, setproducttotal] = useState([]);
  const [totalorder, setordertotal] = useState([]);
  const [totalcategory, setcategorytotal] = useState([]);
  const [totalcoupon, setcoupontotal] = useState([]);
  const [totalemail, setemailtotal] = useState([]);
  const [totalblog, setblogtotal] = useState([]);
  const [totalstore, setstoretotal] = useState([]);
  const [totaluser, setusertotal] = useState([]);
  const getproducttotal = async () => {
    const options = {};
    try {
      const result = await getproducttotalDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setproducttotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getordertotal = async () => {
    const options = {};
    try {
      const result = await getordertotalDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setordertotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getcategorytotal = async () => {
    const options = {};
    try {
      const result = await getcategorytotalDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setcategorytotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getcoupontotal = async () => {
    const options = {};
    try {
      const result = await getcoupontotalDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setcoupontotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getemailtotal = async () => {
    const options = {};
    try {
      const result = await getEmailTotal(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setemailtotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getblogtotal = async () => {
    const options = {};
    try {
      const result = await getBlogDataTotal(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setblogtotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getstoretotal = async () => {
    const options = {};
    try {
      const result = await getStoreTotal(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setstoretotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getusertotal = async () => {
    const options = {};
    try {
      const result = await getUserdeailTotal(options, adminInformation.token);
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setusertotal(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    const adminInfo = localStorage.getItem('ADMIN-INFO');
    getproducttotal();
    getordertotal();
    getcategorytotal();
    getemailtotal();
    getblogtotal();
    getcoupontotal();
    getusertotal();
    getstoretotal();
    if (adminInfo === null) {
      navigate('/login');
    }
  }, [navigate]);
  return (
    <div>
      <body id="page-top">
        {/*  <!-- Page Wrapper --> */}
        <div id="wrapper">
          {/*  <!-- Sidebar --> */}
          <SideBar></SideBar>
          {/*  <!-- End of Sidebar --> */}

          {/*  <!-- Content Wrapper --> */}
          <div id="content-wrapper" className="d-flex flex-column">
            {/*  <!-- Main Content --> */}
            <div id="content">
              {/*  <!-- Topbar --> */}
              <Header></Header>
              {/*  <!-- End of Topbar --> */}

              {/* <!-- Begin Page Content --> */}
              <div className="container-fluid">
                {/*  <!-- Page Heading --> */}
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <Link to="/login" className="collapse-item  welcome_heading">
                    <h1 className="h3 mb-0 text-gray-800 welcome_heading">
                      Welcome To Dashboard
                    </h1>
                  </Link>

                </div>

                <div className="row">
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/categoryList" className="collapse-item">
                                Category :- {totalcategory}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">

                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-th-list fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/productListPage" className="collapse-item">
                                Products:- {totalproduct}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-cubes fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/orderList" className="collapse-item">
                                Order:- {totalorder}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-shopping-cart fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/coupenListPage" className="collapse-item">
                                Coupon :- {totalcoupon}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-tags fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/emailListPage" className="collapse-item">
                                Email:- {totalemail}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-envelope fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/bLogListPage" className="collapse-item">
                                Blog:- {totalblog}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-newspaper fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/storeListPage" className="collapse-item">
                                Store:- {totalstore}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-store fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 dashboard_all_category">
                              <NavLink to="/userList" className="collapse-item">
                                Users:- {totaluser}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800">
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-user fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  <!-- Content Row --> */}

                {/* <div className="row">
                  <div className="col-xl-12 col-lg-7">
                    <div className="card shadow mb-12">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Earnings Overview
                        </h6>
                        <div className="dropdown no-arrow">
                          <a
                            className="dropdown-toggle"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                          </a>
                          <div
                            className="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                            aria-labelledby="dropdownMenuLink"
                          >
                            <div className="dropdown-header">
                              Dropdown Header:
                            </div>
                            <a className="dropdown-item" href="#">
                              Action
                            </a>
                            <a className="dropdown-item" href="#">
                              Another action
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="chart-area">
                          <canvas
                            id="myAreaChart"
                            width="1100"
                            height="400"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div> */}


              </div>
            </div>
            {/*   <!-- End of Main Content -->

                                        <!-- Footer --> */}
            <Footer></Footer>
            {/* <footer className="sticky-footer bg-white">
              <div className="container my-auto">
                <div className="copyright text-center my-auto">
                  <span>Copyright &copy; Your Website 2021</span>
                </div>
              </div>
            </footer> */}
            {/* <!-- End of Footer --> */}
          </div>
          {/*  <!-- End of Content Wrapper --> */}
        </div>
        {/*  <!-- End of Page Wrapper -->

                                <!-- Scroll to Top Button--> */}
        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        {/*  <!-- Logout Modal--> */}
        {/*}  <div
          className="modal fade"
          id="logoutModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Ready to Leave?
                </h5>
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                Select "Logout" below if you are ready to end your current
                session.
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <a className="btn btn-primary" href="login.html">
                  Logout
                </a>
              </div>
            </div>
          </div>
                              </div>*/}
      </body>
    </div>
  );
}

export default Dashboard;
